import "./navbar.scss"
import { Icons, Modal, ModalContents } from "components"
import { useEffect, useRef, useState } from "react"
import { NavLink } from "react-router-dom"

import avatar from "assets/images/png/avatar.png"

import MobileMenu from "Layout/MobileMenu"
import { helpers } from "services"
import useGlobalState from "hooks/useGlobalState"
import { get } from "lodash"
import { dashboard, logistics, marketing, order, store, user } from "components/Sidebar/collections"

export const navbar = [
  {
    permission: [
      "product_list",
      "product_post_list",
      "brand_list",
      "category_list",
      "stream_list",
      "review_list",
      "location_list",
      "account_list",
      "ticket_list",
      "penalty_list",
      "withdraw_list",
      "user_team_list",
      "faq_list",
      "widget_operator_daily_order_statistic",
      "widget_order_explorer",
      "widget_storage_hold_products",
      "widget_orders_count",
      "widget_storage_statistics",
    ],
    to: "/product",
    label: "100k Dashboard",
    sidebar: dashboard,
    icon: <Icons.LifeRing className="life-ring white-icon" />,
  },
  {
    permission: ["user_list", "user_group_list", "user_transactions"],
    to: "/user/",
    label: "Foydalanuvchilar",
    sidebar: user,
    icon: <Icons.User />,
  },
  {
    permission: ["order_list"],
    to: "/order/new",
    label: "Zakaz",
    sidebar: order,
    icon: <Icons.Phone className="permission-icon white-icon" />,
  },
  {
    permission: [
      "store_list",
      "income_list",
      "outgoing_list",
      "store_withdraw_list",
      "revision_list",
      "hold_product_list",
    ],
    to: "/store/",
    label: "Magazin",
    sidebar: store,
    icon: <Icons.Store className="permission-icon white-icon" />,
  },
  {
    permission: ["logistic_storage_list", "order_waybill_list", "pickup_list"],
    to: "/logistics/",
    label: "Logistics",
    sidebar: logistics,
    icon: <Icons.Logistics className="permission-icon white-icon" />,
  },
  {
    permission: [
      "promotion_list",
      "banner_list",
      "contest_list",
      "discount_list",
      "news_list",
      "coupon_list",
      "promocode_list",
    ],
    to: "/marketing/",
    label: "Marketing",
    sidebar: marketing,
    icon: <Icons.Ticket className="permission-icon white-icon" />,
  },
]

function Navbar() {
  const ref = useRef(null)
  const [active, setActive] = useState(false)
  const [isOpen, setOpen] = useState()
  const [state, setState] = useGlobalState()

  const outsideClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setActive(false)
    }
  }

  useEffect(() => {
    window.addEventListener("click", outsideClick)
    return () => window.removeEventListener("click", outsideClick)
  }, [])

  const openMenu = () => {
    const menu = document.querySelector(".mobile-menu")
    if (menu) {
      menu.classList.add("menu-open")
    }
  }

  const signOut = () => {
    localStorage.removeItem("user")
    const tokens = JSON.parse(localStorage.getItem("token"))
    if (Array.isArray(tokens)) tokens.shift()
    localStorage.setItem("token", tokens)
    sessionStorage.removeItem("token")

    setState({ isAuth: false })
    window.localStorage.clear()
  }

  return (
    <>
      <nav className="nav">
        <div className="nav__left">
          {navbar.map(({ permission, label, icon, sidebar }) => {
            const sidebarLinks = helpers.filterNav(sidebar)
            const navLink = get(sidebarLinks, "0.to") || "/"

            return helpers.checkPermissions(permission) ? (
              <div className="nav__items" key={permission}>
                <NavLink to={navLink} className="nav__item">
                  {icon}
                  <p className="nav__item-text">{label}</p>
                </NavLink>
              </div>
            ) : null
          })}
        </div>
        <div className="nav__right">
          <a href="http://100k.uz" target="_blank" rel="noopener noreferrer">
            <Icons.Target width="20px" height="20px" className="nav__target-icon" />
          </a>
          <NavLink to="/">
            <Icons.Widget className="icon-chart" />
          </NavLink>
          <div className="avatar" ref={ref} onClick={() => setActive(!active)}>
            <img src={get(state, "user.avatar_url") || avatar} alt="" />
            <ul
              className={`avatar__dropdown ${active ? "active" : ""}`}
              onClick={(e) => e.stopPropagation()}
            >
              <NavLink to="/profile" onClick={() => setActive(false)}>
                Profil
              </NavLink>
              {helpers.checkPermissions(["crm_settings_list"]) ? (
                <NavLink to="/settings" onClick={() => setActive(false)}>
                  Sozlamalar
                  <Icons.Settings width="15px" />
                </NavLink>
              ) : null}
              <li onClick={() => setOpen(true)}>
                Tizimdan chiqish
                <Icons.Exit width="15px" />
              </li>
            </ul>
          </div>
          <div className="nav__burger" onClick={openMenu}>
            <span> </span>
            <span> </span>
            <span> </span>
          </div>
        </div>
        <Modal isOpen={isOpen} handleClose={() => setOpen(false)} title="Tizimdan chiqish">
          <ModalContents.Confirm
            onConfirm={signOut}
            onReject={() => setOpen(false)}
            text="Tizimdan chiqishni xohlaysizmi?"
          />
        </Modal>
      </nav>
      <MobileMenu />
    </>
  )
}

export default Navbar
